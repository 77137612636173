import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '../../constants';
import { getAccessToken } from '../../service/auth';
import { MailCodeTypes } from './mail';
import { SmsCodeTypes } from './sms';
import { PrivateScopesMode } from '../../requests/user';
import { TUserProfile } from '../userSlice';
import { userParamsToFormData } from '../../helpers';

export type TExternalAccount = {
  id: string;
  avatar?: string;
  email?: string;
  family_name?: string;
  given_name?: string;
  issuer: string;
  sub: string;
  type: AccountTypes;
  update_password_at: string;
  provider_id: number;
  profile_link?: string;
};

export type PrivateClaims = {
  scopes: string;
  public_profile_claims_oauth?: string;
  public_profile_claims_gravatar?: string;
  public_accounts_claims_oauth?: number[];
  public_accounts_claims_gravatar?: number[];
};

export enum AccountTypes {
  GOOGLE = 'GOOGLE',
  YANDEX = 'YANDEX',
  VK = 'VK',
  MAILRU = 'MAILRU',
  CUSTOM = 'CUSTOM',
  EMAIL = 'EMAIL',
  KLOUD = 'KLOUD',
  QRCODE = 'QRCODE',
  LDAP = 'LDAP',
  ALDPRO = 'ALDPRO',
  ETHEREUM = 'ETHEREUM',
  SMS = 'SMS',
  _1C = 'Account1C',
  IDM = 'IDM',
  PHONE = 'PHONE',
  ESIA = 'ESIA',
}

export const userApi = createApi({
  reducerPath: 'userApi',
  tagTypes: ['Claims', 'ExternalAccounts', 'User'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUser: builder.query<Omit<TUserProfile, 'id'> & { sub: string }, void>({
      query: () => ({
        url: `${BACKEND_URL}/api/oidc/me`,
      }),
      providesTags: ['User'],
    }),

    getExternalAccounts: builder.query<TExternalAccount[], string>({
      query: (user_id) => ({
        url: `user/v1/external_account/${user_id}`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'ExternalAccounts' as const,
                id,
              })),
              { type: 'ExternalAccounts', id: 'LIST' },
            ]
          : [{ type: 'ExternalAccounts', id: 'LIST' }],
    }),

    getPublicExternalAccounts: builder.query<
      TExternalAccount[],
      { client_id: string; user_id: string }
    >({
      query: ({ client_id, user_id }) => ({
        url: `user/v1/public_external_account/${client_id}/${user_id}`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'ExternalAccounts' as const,
                id,
              })),
              { type: 'ExternalAccounts', id: 'LIST' },
            ]
          : [{ type: 'ExternalAccounts', id: 'LIST' }],
    }),

    changeEmail: builder.mutation<
      void,
      {
        email: string;
        userId: string;
        code: string;
        type: MailCodeTypes;
        /* #992 rebind: boolean;
        currentEmailId?: string | null;*/
      }
    >({
      query: ({ email, code, type }) => {
        return {
          url: `v1/user/profile/email`,
          method: 'PUT',
          body: 'code=' + code + '&email=' + email + '&code_type=' + type /* #992+
            '&rebind=' +
            rebind +
            (currentEmailId ? '&currentEmailId=' + currentEmailId : '')*/,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        };
      },
      invalidatesTags: [{ type: 'ExternalAccounts', id: 'LIST' }, { type: 'User' }],
    }),

    addEmail: builder.mutation<
      void,
      {
        newEmail: string;
        userId: string;
        code: string;
        // #992 rebind: boolean;
      }
    >({
      query: ({ newEmail, code }) => {
        return {
          url: `v1/user/profile/email`,
          method: 'POST',
          body:
            'code=' + code + '&email=' + newEmail + '&code_type=' + MailCodeTypes.addEmail /* #992+
            '&rebind=' +
            rebind*/,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        };
      },
      invalidatesTags: [
        { type: 'ExternalAccounts', id: 'LIST' },
        { type: 'Claims', id: 'LIST' },
        { type: 'User' },
      ],
    }),

    addPhoneBySms: builder.mutation<
      void,
      {
        phoneNumber: string;
        userId: string;
        code: string;
        rebind: boolean;
        provider_id: string;
      }
    >({
      query: ({ phoneNumber, userId, code, rebind, provider_id }) => {
        return {
          url: `user/v1/add_phone_by_sms/${userId}`,
          method: 'POST',
          body:
            'code=' +
            code +
            '&phoneNumber=' +
            encodeURIComponent(phoneNumber) +
            '&code_type=' +
            SmsCodeTypes.addPhone +
            '&rebind=' +
            rebind +
            '&provider_id=' +
            provider_id,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        };
      },
      invalidatesTags: [
        { type: 'ExternalAccounts', id: 'LIST' },
        { type: 'Claims', id: 'LIST' },
        { type: 'User' },
      ],
    }),

    changePassword: builder.mutation<
      void,
      {
        newPassword: string;
        userId: string;
        revokeTokens?: boolean;
      }
    >({
      query: ({ newPassword, userId, revokeTokens }) => {
        return {
          url: `user/v1/password/${userId}`,
          method: 'PUT',
          body: 'password=' + newPassword + (revokeTokens ? '&revoke_tokens=' + revokeTokens : ''),
          headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        };
      },
    }),

    restoreProfile: builder.mutation<void, { userId: string }>({
      query: ({ userId }) => {
        return {
          method: 'PUT',
          url: `user/v1/restore/${userId}`,
        };
      },
    }),

    deleteExternalAccount: builder.mutation<void, { userId: number; accountId: string }>({
      query: ({ userId, accountId }) => {
        return {
          method: 'DELETE',
          url: `v1/users/${userId}/external_accounts/${accountId}`,
        };
      },
      invalidatesTags: [{ type: 'ExternalAccounts', id: 'LIST' }],
    }),

    setMainEmail: builder.mutation<void, { email: string; userId: string }>({
      query: ({ email, userId }) => {
        return {
          method: 'PUT',
          url: `user/v1/set_main_email/${userId}`,
          body: { email },
        };
      },
      invalidatesTags: [{ type: 'ExternalAccounts', id: 'LIST' }, { type: 'User' }],
    }),

    bindAccount: builder.mutation<
      { success: boolean; nickname?: string },
      { userId: string; username: string; password: string; provider_id: string; rebind?: boolean }
    >({
      query: ({ userId, ...body }) => {
        return {
          method: 'PUT',
          url: `user/v1/add_account/${userId}`,
          body,
        };
      },
      invalidatesTags: [
        { type: 'ExternalAccounts', id: 'LIST' },
        { type: 'Claims', id: 'LIST' },
      ],
    }),

    bindEthereumAccount: builder.mutation<
      { success: boolean; nickname?: string; binded_to_this_user: boolean },
      { userId: string; address: string; signature: string; rebind?: boolean; client_id: string }
    >({
      query: ({ userId, ...body }) => {
        return {
          method: 'PUT',
          url: `user/v1/add_ethereum_account/${userId}`,
          body,
        };
      },
      invalidatesTags: [
        { type: 'ExternalAccounts', id: 'LIST' },
        { type: 'Claims', id: 'LIST' },
      ],
    }),

    getPrivateClaims: builder.query<PrivateClaims, string>({
      query: (user_id) => ({
        url: `user/v1/private_scopes/${user_id}`,
      }),
      providesTags: [{ type: 'Claims', id: 'LIST' }],
    }),

    changeClaimPrivacy: builder.mutation<
      void,
      {
        userId: string;
        privacyMode: PrivateScopesMode;
        claims?: string;
        public_accounts_to_edit?: number[];
      }
    >({
      query: ({ claims, privacyMode, userId, public_accounts_to_edit }) => {
        const body = {
          mode: privacyMode,
          claims_to_edit: claims,
          public_accounts_to_edit,
        };

        return {
          url: BACKEND_URL + '/api/user/v1/private_scopes/' + userId,
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body,
        };
      },
      invalidatesTags: [{ type: 'Claims', id: 'LIST' }],
    }),

    bindKloudAccount: builder.mutation<
      void,
      { userId: string; issuer: string; code: string; rebind?: boolean }
    >({
      query: ({ userId, ...body }) => {
        return {
          method: 'POST',
          url: `user/v1/add_kloud_account/${userId}`,
          body,
        };
      },
      invalidatesTags: [
        { type: 'ExternalAccounts', id: 'LIST' },
        { type: 'Claims', id: 'LIST' },
      ],
    }),

    addPhone: builder.mutation<void, { code: string; phone_number: string }>({
      query: (body) => {
        return {
          method: 'POST',
          url: `v1/user/profile/phone_number`,
          body,
        };
      },
      invalidatesTags: [{ type: 'Claims', id: 'LIST' }, { type: 'User' }],
    }),

    updateUser: builder.mutation<void, { userId: string; data: Partial<TUserProfile> }>({
      query: ({ userId, data }) => {
        return {
          method: 'PUT',
          url: `v1/users/${userId}`,
          body: userParamsToFormData(data),
        };
      },
      invalidatesTags: [{ type: 'User' }],
    }),
  }),
});

export const {
  useChangeEmailMutation,
  useGetExternalAccountsQuery,
  useGetPublicExternalAccountsQuery,
  useLazyGetExternalAccountsQuery,
  useDeleteExternalAccountMutation,
  useBindAccountMutation,
  useBindEthereumAccountMutation,
  useGetPrivateClaimsQuery,
  useChangeClaimPrivacyMutation,
  useChangePasswordMutation,
  useAddEmailMutation,
  useAddPhoneBySmsMutation,
  useRestoreProfileMutation,
  useSetMainEmailMutation,
  useBindKloudAccountMutation,
  useAddPhoneMutation,
  useGetUserQuery,
  useUpdateUserMutation,
} = userApi;
